
const ParticipantProfileLine = ({ participants }) => {
  return (
    <div className="container-participants">
      {participants.map((value) => (
        <div className="container-participant" key={value.name}>
          <img src={`/assets/participants/${value.picture}`} alt={`Imagem do membro ${value.name}`} />
          <div>
            <p>{value.name}</p>
            <span>
              <img src="/assets/icons/instagram.svg" alt="logo Instagram" />{value.instagram}</span>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ParticipantProfileLine;