import './styles/App.css';
import Header from './pages/header';
import Participant from './pages/Participant';
import About from './pages/About';

const App = () => {
  return (
    <>
      <Header />
      <About />
      <Participant />
    </>
  );
}

export default App;
