const Header = () => {
  return (
    <header id="#" className="header container">
      <div >
        <img src="/assets/logo.png" alt="logo" />
        <h1>
          Pinakay's
        </h1>

      </div>
      <nav>
        <a href="#events">
          Eventos
        </a>
        <a href="#participants">
          Participantes
        </a>
        <a href="#about">
          Sobre
        </a>
      </nav>
    </header>
  )
}

export default Header;